import { render, staticRenderFns } from "./LowerMatch.vue?vue&type=template&id=112ac2aa&scoped=true"
import script from "./LowerMatch.vue?vue&type=script&lang=js"
export * from "./LowerMatch.vue?vue&type=script&lang=js"
import style0 from "./LowerMatch.vue?vue&type=style&index=0&id=112ac2aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112ac2aa",
  null
  
)

export default component.exports