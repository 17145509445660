<template>
  <div class="back-button-container">
    
  </div>
</template>

<style scoped>
.back-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
}

.back-button {
  display: inline-flex;
  align-items: center;
}


</style>

<script>
export default {
  name: 'BackButton',
  // Add other properties or methods as needed
};
</script>
