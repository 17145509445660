var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backgrouond"},[_c('div',[_c('DatePicker',{on:{"date-selected":_vm.handleDateSelected}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center","margin":"10px"}},[_c('router-link',{staticStyle:{"display":"inline-flex","align-items":"center"},attrs:{"to":"/"}},[_c('img',{staticStyle:{"width":"24px","height":"auto","margin-right":"8px"},attrs:{"src":require("@/assets/logo/back.png"),"alt":"Back"}})])],1),_c('div',{staticClass:"container"},_vm._l((_vm.items),function(item,index){return _c('MatchTableItem',{key:index,attrs:{"time":item.startDate,"event":item.event,"edisciplineName":item.disciplineName,"team1":{
        name: item.competitors[0].name,
        flag: item.competitors[0].noc,
        score: item.competitors[0].mark,
        winnerLoserTie: item.competitors[0].winnerLoserTie,
      },"team2":{
        name: item.competitors[1].name,
        flag: item.competitors[1].noc,
        score: item.competitors[1].mark,
        winnerLoserTie: item.competitors[1].winnerLoserTie,
      }},on:{"match-selected":_vm.navigateToMatch}})}),1),_c('div',{staticStyle:{"height":"200px"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }